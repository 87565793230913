import { useState } from 'react';
import StartChatButton from './StartChatButton';
import Modal from './Modal';

export default function Navbar() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <nav className='shadow-lg w-full bg-white'>
      <div className='mx-auto px-4 md:px-14'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <p className='text-[30px] font-semibold font-mustica-pro'>TELL</p>
            <img
              className='mx-2'
              src='./assets/LOGO.png'
              width={33}
              height={34}
              alt='Nav_Logo'
            />
          </div>
          <ul className='flex space-x-4 list-none items-center'>
            <li
              onClick={handleModalOpen}
              className='text-[15px] cursor-pointer text-deepskyblue-100 font-sans hidden md:block'
            >
              Contact Us
            </li>
            <li className='cursor-pointer'>
              <StartChatButton />
            </li>
          </ul>
          <Modal open={modalOpen} onClose={handleModalClose} />
        </div>
      </div>
    </nav>
  );
}
