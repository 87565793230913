import React from 'react';
import { InputFieldProps } from '../types/interface';

const InputField: React.FC<InputFieldProps> = ({ label, id, placeholder, name, value, onChange }) => {
    return (
        <div className="mt-4 md:mt-0">
            <label htmlFor={id} className="block text-[10px] text-dimgray mb-2">
                {label}
            </label>
            <input
                type="text"
                id={id}
                name={name}
                required
                value={value}
                onChange={onChange}
                className="w-11/12 lg:w-[280px] px-3 py-2 border-2 border-lightgray rounded-md"
                placeholder={placeholder}
            />
        </div>
    );
};

export default InputField;
