import React from 'react';
import { TextAreaFieldProps } from '../types/interface';

const TextAreaField: React.FC<TextAreaFieldProps> = ({ label, id, rows, value, name, onChange }) => {
    return (
        <div className="mb-4">
            <div className="mx-3">
                <label htmlFor={id} className="block text-[10px] text-dimgray mb-2">
                    {label}
                </label>
                <textarea
                    id={id}
                    value={value}
                    required
                    name={name}
                    onChange={onChange}
                    className="w-full py-2 border border-lightgray rounded-md"
                    rows={rows}
                />
            </div>
        </div>
    );
};

export default TextAreaField;
