import { FunctionComponent } from 'react';

const StartChatButton: FunctionComponent = () => (
  <button
    style={{ border: '2px solid #29abee' }}
    className='flex items-center cursor-pointer px-4 py-2 bg-white font-inter text-md text-deepskyblue-100 text-sm font-medium rounded-full border-deepskyblue-100 border-2'
  >
    Start Chat
    <img className='w-[22px] h-[22px] mx-2' alt='' src='/assets/vector3.svg' />
  </button>
);

export default StartChatButton;
