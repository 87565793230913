import StartChatButton from './StartChatButton';

export default function HeroSection() {
  return (
    <div className='bg-contain bg-white'>
      <div className='h-full w-full flex items-center justify-center'>
        <div className='flex flex-col md:flex-row justify-around w-full p-8 '>
          <div className='text-black font-inter flex flex-col md:items-start items-center'>
            <h1 className='text-3xl md:text-5xl !leading-snug font-semibold'>
              Welcome to Your <br />
              <span className='bg-deepskyblue-100 shadow-text-shadow text-white pl-2 px-4'>
                AI Assist Assistant
              </span>{' '}
              <br /> for <span className='text-deepskyblue-100'>Telegram</span>
            </h1>
            <p className='text-dimgray'>
              Get your AI Assistant Powered by ChatGPT <br />
              on your Telegram
            </p>
            <div className='mt-3 flex justify-center md:block'>
              <div className='mb-10 md:mb-0'>
                <StartChatButton />
              </div>
            </div>
          </div>
          <div className='col-span-1 py-20'>
            <img
              src='./assets/sideImagee.png'
              alt='Side Bar Image'
              className='w-full h-auto'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
