import { useState } from 'react'
import React from 'react';
import InputField from './InputField';
import TextAreaField from './TextArea';
import { ModalProps } from '../types/interface';


const Modal: React.FC<ModalProps> = ({ open, onClose }) => {


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNo: '',
        message: '',
    });


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();


        const { firstName, lastName, email, message } = formData;
        const mail = 'info@geeksofkolachi.com'
        const subject = 'Contact Form Submission';
        const body = `Name: ${firstName} ${lastName}\nEmail: ${email}\nMessage: ${message}`;
        const mailtoLink = `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.open(mailtoLink, '_blank');

        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            contactNo: '',
            message: '',
        });
    };


    if (!open) return null;

    return (
        <div className="fixed top-0 font-inter left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-60">
            <div className="bg-white rounded-md w-9/12 lg:w-6/12 px-6 py-4 m-auto">
                <div>
                    <div className='flex items-center'>
                        <h2 className="lg:text-2xl font-bold mb-4">Contact Us</h2>
                        <div className="flex-grow"></div>
                        <img onClick={onClose} className='cursor-pointer' src="/assets/close.png" alt="Icon Button" />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <div className="block md:flex justify-between px-2 flex-wrap">
                                <InputField value={formData.firstName} name='firstName' onChange={handleChange} label="FIRST NAME" id="first-name" placeholder="Enter your first name" />
                                <InputField value={formData.lastName} name='lastName' onChange={handleChange} label="LAST NAME" id="last-name" placeholder="Enter your last name" />
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="block md:flex justify-between px-2 flex-wrap">
                                <InputField value={formData.email} name='email' onChange={handleChange} label="EMAIL" id="email" placeholder="Enter your email" />
                                <InputField value={formData.contactNo} name='contactNo' onChange={handleChange} label="CONTACT NUMBER" id="contact-number" placeholder="Enter your contact number" />
                            </div>
                        </div>

                        <TextAreaField label="MESSAGE" name='message' value={formData.message} onChange={handleChange} id="message" rows={4} />

                        <div className="text-center">
                            <button
                                className="bg-blue-500 w-6/12 m-auto md:6/12 h-[50px] outline-none hover:bg-blue-600 text-white px-4 py-2 rounded-full"
                                type='submit'
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Modal;
