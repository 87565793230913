import { FunctionComponent } from 'react';

const Footer: FunctionComponent = () => {
  const footerLinks = ['Terms', 'Privacy Policy', 'Support', 'Contact Us'];

  return (
    <footer className='bg-deepskyblue-100 flex flex-col md:flex-row flex-wrap px-10 py-3 justify-between items-center mt-10'>
      <div className='font-inter text-white'>
        <div className='flex items-center md:justify-start justify-center'>
          <p className='mr-2'>TELL</p>
          <img
            className='w-8 h-8'
            src='/assets/logo-white.png'
            alt='Logo white image'
          />
        </div>
        <div>
          <p className='text-sm footer-text'>
            &copy; 2023 TellAI. All rights reserved
          </p>
        </div>
      </div>

      <div className='text-center relative md:top-8'>
        <ul className='list-none flex sm:flex-row flex-col flex-wrap md:flex-nowrap gap-x-8 footer-text justify-center -left-5 relative'>
          {footerLinks.map((linkText, index) => (
            <li
              key={index}
              className='text-white font-inter cursor-pointer mt-2 sm:mt-0'
            >
              {linkText}
            </li>
          ))}
        </ul>
      </div>

      <div className='md:w-auto'>
        <p className='footer-text text-white font-inter md:text-left text-center md:mt-0 mt-8'>
          A Product by
        </p>
        <img className='md:w-auto' src='/assets/name.png' alt='' />
      </div>
    </footer>
  );
};

export default Footer;
